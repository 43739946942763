import Logo from "./images/Moonlight-new-full.png";
import Traffic from "./images/traffic.png";
import School from "./images/school.png";
import Safety from "./images/safety.png";
import GiveWay from "./images/giveway.png";
import Forty from "./images/40zone.png";
import Sixty from "./images/60zone.png";
import Eighty from "./images/80zone.png";

import "./App.css";

function App() {
  return (
    <div className="App">
      <nav
        className="navbar navbar-expand-md navbar-dark bg-dark fixed-top"
        id="navbar"
      >
        <img className="navbar-brand" id="logo" src={Logo} height="150px" />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExampleDefault"
          aria-controls="navbarsExampleDefault"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
          <div className="car" id="car"></div>
          <ul id="ul" className="navbar-nav mr-auto">
            <li className="nav-item">
              <a className="nav-link active" href="#home">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item nav-link nav-separator">|</li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                About
              </a>
            </li>
            <li className="nav-item nav-link nav-separator">|</li>
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Services
              </a>
            </li>
            <li className="nav-item nav-link nav-separator">|</li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contact
              </a>
            </li>
            <li className="nav-item nav-link nav-separator">|</li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href="https://www.facebook.com/groups/411642425544903"
              >
                Our Learners
              </a>
            </li>
            <li className="nav-item nav-link nav-separator">|</li>
            <li className="nav-item">
              <a
                className="nav-link"
                target="_blank"
                href="https://square.site/book/LNR2D9WMHM6Y1/moonlight-driving-school"
              >
                Book Now!
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div id="home" className="section">
        <div id="hero">
          <div className="title-anim">
            <h1 id="cssanim" className="cssanimation sequence leFlyInBottom">
              Moonlight Driving School
            </h1>
          </div>
          <rssapp-carousel id="n1NlcgmtXYBOXsT8"></rssapp-carousel>
          <script
            src="https://widget.rss.app/v1/carousel.js"
            type="text/javascript"
            async
          ></script>
        </div>
        <main role="main" className="container">
          <div className="text-center mt-5 pt-5 banner">
            <img src={Traffic} style={{ height: "50px", float: "left" }} />
            <img src={Traffic} style={{ height: "50px", float: "right" }} />
            <h1>
              First Class Driving Instructing in Melbourne's Northern Suburbs
            </h1>
            <div className="row">
              <div className="col-lg">
                <p>
                  <i className="far fa-clock fa-3x"></i>
                  30 + years experience
                </p>
              </div>
              <div className="col-sm">
                <p>
                  <i className="far fa-id-badge fa-3x"></i>
                  3000+ licenses issued
                </p>
              </div>
            </div>
          </div>
          <div id="about" className="section"></div>
          <div className="text-center mt-5 pt-5 container">
            <h1>Book your first lesson today!</h1>
            <br />
            <br />
            <div className="row">
              <div className="column">
                <table className="table table-hover prices">
                  <th scope="col" colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      BOOK NOW
                    </a>
                  </th>
                  <tr>
                    <td>45 minutes</td>
                    <td>$60</td>
                  </tr>
                  <tr>
                    <td>1 hour</td>
                    <td>$70</td>
                  </tr>
                  <tr>
                    <td>1.5 hours</td>
                    <td>$110</td>
                  </tr>
                  <tr>
                    <td>2 hours</td>
                    <td>$130</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <a href="#services" data-width="1600" data-height="1000">
                        Full price list
                      </a>
                    </td>
                  </tr>
                </table>
                <div className="column">
                  <img
                    className="sign"
                    src={School}
                    style={{ height: "250px", float: "right" }}
                  />
                  <p className="lead">
                    Moonlight Driving School is one of the oldest, most trusted
                    and successful driving schools in Melbourne. With over 30
                    years of experience, we have been providing driving lessons
                    for thousands of people in Melbourne from young to older
                    learners, people converting from an overseas license, to
                    those wanting to refresh their driving skills. We offer
                    affordable, effective, and flexible driving lessons in your
                    area and have well trained and professional driving
                    instructors who will make sure that you get taught all the
                    required criteria and techniques to pass your driving test
                    on the first attempt.
                  </p>
                </div>
                <p className="lead">
                  Moonlight driving School was established in 1990 and has been
                  in operation for over 30 consecutive years. All our
                  instructors are fully qualified under the rigorous bench mark
                  of R.T.A, now known as VicRoads. Moonlight driving school is a
                  member of the Australian Driver Trainers Association
                  (A.D.T.A), formerly Victorian Motor School Association
                  (V.M.S.A.) accredited member no 021 since 1990 . Accredited
                  Keys2drive Instructor since 2012. Since 1990 to 2021 over
                  3,000 Learners and Overseas license holders got their
                  Victorian licenses - 99% of them got their licenses on just
                  the first attempt! See our testimonials for more info!
                </p>
                <a
                  href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                  className="html5lightbox"
                  data-width="1600"
                  data-height="1000"
                >
                  <button type="button" className="btn btn-primary">
                    Book Now
                  </button>
                </a>
              </div>
            </div>
            <div className="text-center mt-5 pt-5 banner">
              <img src={Traffic} style={{ height: "50px", float: "left" }} />
              <img src={Traffic} style={{ height: "50px", float: "right" }} />
              <h1>Servicing Melbourne's Northern Suburbs</h1>
              <img
                className="sign"
                src={GiveWay}
                style={{ height: "150px", float: "top" }}
              />
              <img
                className="sign"
                src={Forty}
                style={{ height: "150px", float: "top" }}
              />
              <img
                className="sign"
                src={Sixty}
                style={{ height: "150px", float: "top" }}
              />
              <img
                className="sign"
                src={Eighty}
                style={{ height: "150px", float: "top" }}
              />
              <img
                className="sign"
                src={Safety}
                style={{ height: "150px", float: "top" }}
              />
            </div>
            <div id="services" className="section"></div>
          </div>
          <div
            className="text-center mt-5 pt-5 container"
            style={{ marginTop: "20px !important" }}
          >
            <div>
              <h1 style={{ textAlign: "center" }}>Find Out More!</h1>
              <h4>All packages include 1 free Keys2Drive lesson!</h4>
              <br />
              <table className="table table-hover price-table">
                <td className="tabhead">
                  <h1>45 Minutes</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 1</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 2</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 3</h1>
                </td>
                <td className="tabhead">
                  <h1>Test Package</h1>
                </td>
                <tr>
                  <td>Lessons</td>
                  <td>5</td>
                  <td>10</td>
                  <td>15</td>
                  <td>1 + Test</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>45 minutes</td>
                  <td>45 minutes</td>
                  <td>45 minutes</td>
                  <td>1.5 hours + Test</td>
                </tr>
                <tr>
                  <td>Valued At</td>
                  <td>$300.00</td>
                  <td>$600.00</td>
                  <td>$900.00</td>
                  <td>N/A</td>
                </tr>
                <tr id="discount">
                  <td>Discount</td>
                  <td>$15.00</td>
                  <td>$60.00</td>
                  <td>$135.00</td>
                  <td>N/A</td>
                </tr>
                <tr id="price">
                  <td>Final Price</td>
                  <td>$285.00</td>
                  <td>$540.00</td>
                  <td>$765.00</td>
                  <td>$260.00</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover price-table">
                <td className="tabhead">
                  <h1>60 Minutes</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 4</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 5</h1>
                </td>
                <td className="tabhead">
                  <h1>Package 6</h1>
                </td>
                <td className="tabhead">
                  <h1>Test Package</h1>
                </td>
                <tr>
                  <td>Lessons</td>
                  <td>5</td>
                  <td>10</td>
                  <td>15</td>
                  <td>1 + Test</td>
                </tr>
                <tr>
                  <td>Duration</td>
                  <td>60 minutes</td>
                  <td>60 minutes</td>
                  <td>60 minutes</td>
                  <td>1.5 hours + Test</td>
                </tr>
                <tr>
                  <td>Valued At</td>
                  <td>$350.00</td>
                  <td>$700.00</td>
                  <td>$10500.00</td>
                  <td>N/A</td>
                </tr>
                <tr id="discount">
                  <td>Discount</td>
                  <td>$17.50</td>
                  <td>$70.00</td>
                  <td>$157.50</td>
                  <td>N/A</td>
                </tr>
                <tr id="price">
                  <td>Final Price</td>
                  <td>$332.50</td>
                  <td>$630.00</td>
                  <td>$892.50</td>
                  <td>$260.00</td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                  <td>
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <h3 className="package-head">45 Minute Lesson Packages</h3>
              <br />
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 1
                </th>
                <tr>
                  <td>5 x 45 Minutes</td>
                </tr>
                <tr>
                  <td>Only $285!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 2
                </th>
                <tr>
                  <td>10 x 45 Minutes</td>
                </tr>
                <tr>
                  <td>Only $540.00!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 3
                </th>
                <tr>
                  <td>15 x 45 Minutes</td>
                </tr>
                <tr>
                  <td>Only $765.00!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <h3 className="package-head">60 Minute Lesson Packages</h3>
              <br />
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 4
                </th>
                <tr>
                  <td>5 x 60 Minutes</td>
                </tr>
                <tr>
                  <td>Only $332.50!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 5
                </th>
                <tr>
                  <td>10 x 60 Minutes</td>
                </tr>
                <tr>
                  <td>Only $630.00!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Package 6
                </th>
                <tr>
                  <td>15 x 60 Minutes</td>
                </tr>
                <tr>
                  <td>Only $892.50!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table table-hover table-striped packages">
                <th scope="col" colSpan="2">
                  Test Package
                </th>
                <tr>
                  <td>90 Minutes + Driving Test</td>
                </tr>
                <tr>
                  <td>Only $260.00!</td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <a
                      href="https://squareup.com/appointments/buyer/widget/tqq93d0jd05igy/LNR2D9WMHM6Y1?color=5d97c9&amp;locale=en-US"
                      className="html5lightbox"
                      data-width="1600"
                      data-height="1000"
                    >
                      <button type="button" className="btn btn-primary">
                        Book Now
                      </button>
                    </a>
                  </td>
                </tr>
              </table>
              <p
                className="lead"
                style={{ paddingBottom: "25px", textAlign: "center" }}
              >
                We are accredited by Keys 2 Drive providers. Register now at{" "}
                <a target="_blank" href="https://keys2drive.com.au">
                  keys2drive.com.au
                </a>{" "}
                and call or email us to book a professional driving instructors.
              </p>
              <iframe
                className="map"
                src="https://maps.co/embed/6012538c0bd5b277603903da1453"
                width="600"
                height="400"
                frameBorder="0"
                allowFullScreen
              ></iframe>
              {/* <!--<img className="map" src="moonlight-map.PNG">--> */}
              <table className="table hours">
                <th scope="col" colSpan="2">
                  Contact Details
                </th>
                <tr>
                  <td>
                    <i className="fas fa-phone-square-alt"></i>Phone
                  </td>
                  <td>
                    <a href="tel:+61393054896">(03) 9305 4896</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-mobile-alt"></i> Mobile
                  </td>
                  <td>
                    <a href="tel:+61401739802">0401 739 802</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <i className="fas fa-envelope-square"></i>Email
                  </td>
                  <td>
                    <a href="mailto:moonlight_driving@bigpond.com">
                      moonlight_driving@bigpond.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td scope="col" colSpan="2">
                    <i className="fab fa-facebook-square"></i>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/groups/411642425544903"
                    >
                      Facebook
                    </a>
                  </td>
                </tr>
              </table>
              <table className="table hours">
                <th scope="col" colSpan="2">
                  Operating Hours
                </th>
                <tr>
                  <td>Monday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Tuesday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Wednesday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Thursday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Friday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Saturday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
                <tr>
                  <td>Sunday</td>
                  <td>8:00am-4:00pm</td>
                </tr>
              </table>
            </div>
            <div id="contact" className="section"></div>
            <div className="contact">
              <h2 style={{ textAlign: "center" }}>Contact Us</h2>
              <br />
              <br />
              <iframe
                src="https://moonlight-driving-school.square.site"
                title="Moonlight Driving School"
                scrolling="no"
                width="75%"
                height="500"
                style={{
                  border: "none",
                  margin: "0 auto",
                  display: "block",
                  textAlign: "center",
                }}
              ></iframe>
            </div>
            <br />
            <h2>Accreditations</h2>
            <p className="lead">
              <ol style={{ textAlign: "left" }}>
                <li>
                  Driving Instructor Licensed by RTA now Vic Roads by passing
                  (3)Vic Roads Instructor Criteria :
                  <ul>
                    <li>Instructors knowledge test</li>
                    <li>Instructors Practical driving with legal Commentary</li>
                    <li>
                      Instructors Teaching technique with legal commentary.
                      Successfully attended January 1990
                    </li>
                  </ul>
                </li>
                <li>
                  Successfully attended the Victorian Motor Schools Association
                  of Victoria (VMSA) now Australian Drivers Trainers Association
                  of Victoria (ADTAV) the : TRAIN THE TRAINER PROGRAM and
                  achieved competence in designing, delivering and evaluating
                  training in Relation to one-to-one motor vehicle driver
                  instruction dated 18th, 25th and December 2nd 1993.
                </li>
                <li>
                  Accredited and approved Instructor number 021 by Victorian
                  Motor School Association (VMSA) now called Australian Drivers
                  Trainers Association of Victoria (ADTAV) in 1993.
                </li>
                <li>
                  Successfully completed 2311AEA CERTIFICATE 111 IN ROAD
                  TRANSPORT ( MOTOR VEHICLE DRIVING INSTRUCTION) at Moonee Ponds
                  Driving School , Dated 29 of March 1999.
                </li>
                <li>
                  Keys2drive Accredited Instructor since 8th of November 2010.
                </li>
                <li>
                  Successfully Completed Online Training of the Keys2drive SAFER
                  CARS TRAINING MODULE Dated 14th of July 2019.
                </li>
              </ol>
            </p>
            <div>
              <br />
              <h2>Cancellation policy</h2>
              <p
                className="lead"
                style={{
                  textAlign: "left !important",
                  padding: "20px !important",
                }}
              >
                Please give 24 hours notice of cancellation or a $25 re-booking
                charge will apply.
                <br />
                To validate and confirm an online booking, you must pay by bank
                transfer 2 days prior to the booked driving lesson.
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
